import * as VertisFazPraMimService from "../../../../service/VertisFazPraMimService";
import { useEffect, useState } from "react";
import { Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import Moment from "react-moment";
import Swal from "sweetalert2";
import { Button, Col, Row } from "react-bootstrap";

/**
* @author Guilherme Almeida
* @date 02/09/2021
*/
function FazPraMim() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [list, setList] = useState([]);

  useEffect(() => {
    VertisFazPraMimService.findResume().then((list) => setList(list));
  }, []);
  
  function handleUpgrade() {

    Swal.fire({
      icon: "warning",
      title: "Confirme!",
      text: `Ao executar o @FazPraMim poderá ter um impacto no sistema, tem certeza disso???`,
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        setIsSubmitting(true);
        VertisFazPraMimService.updateir21files()
          .then(() => alert("Feito!"))
          .catch(() => alert("Ocorreu algum erro inesperado!"))
          .finally(() => setIsSubmitting(false));
      }
    });
  }

  const columns = [
    {
      title: "File",
      field: "filePathname",
    },
    {
      title: "Raex Version",
      field: "tadigRaexVersion",
    },
    {
      title: "File Date",
      field: "raexFileCreationDate",
      render: (row) => <Moment format="DD/MM/yyyy HH:mm:ss">{row.raexFileCreationDate}</Moment>,
    },
    {
      title: "IR.21 / IR.85",
      field: "ir85",
      render: (row) => row.ir85 ? "IR.21" : "IR.85",
    }, 
    {
      title: "HASH",
      field: "raexFileHash",
    },
  ];

  return (
    <Row>
      <Col>
        <div className="ibox">
          <div className="ibox-title d-flex justify-content-between align-items-center">
            <h5>FazPraMim - List</h5>
            <div>
              <Button 
                className="btn btn-primary"
                type="submit"
                onClick={() => handleUpgrade()}
                disabled={isSubmitting}
              >
                FAZ PRA MIM! 
              </Button>
            </div>
          </div>
          <div className="ibox-content">
            <MaterialTable
              title=""
              columns={columns}
              data={list}
              components={{
                Container: (props) => <Paper {...props} elevation={0} />,
              }}
              options={{
                rowStyle: {
                  fontSize: 13,
                },
                pageSize: 10,
                pageSizeOptions: [5, 10, 15, 20],
                actionsColumnIndex: -1,
                exportButton: false,
                searchFieldAlignment: "left",
                toolbarButtonAlignment: "right",
                exportFileName: "list",
                headerStyle: {
                  textTransform: "uppercase",
                }
              }}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default FazPraMim;
