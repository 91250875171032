import { Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  FiCheck,
  FiDownload,
  FiEdit,
  FiFileMinus,
  FiFilePlus,
  FiFileText,
  FiTrash2,
  FiX,
} from "react-icons/fi";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Ir21TemplateModal from "../../../../../components/Ir21/Ir21TemplateModal";
import {
  ErrorUnexpected,
  OperationSuccessfully,
} from "../../../../../const/Messages";
import * as Ir21Service from "../../../../../service/Ir21Service";
import * as PlmnService from "../../../../../service/PlmnService";
import Ir21SearchModal from "../../../Ir21/Search/Ir21SearchModal";

/**
  @author Guilherme Almeida
  @date 10/02/2021
 */
export default function ManagementTadigList() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [plmn, setPlmn] = useState([]);
  const [ir21DataModal, setIr21DataModal] = useState({
    isOpen: false,
    plmnId: 0,
  });

  const [showModal, setShowModal] = useState(false);
  const [organisationId, setOrganisationId] = useState("");
  const [organisationName, setOrganisationName] = useState("");

  useEffect(updateData, []);

  function updateData() {
    setIsLoading(true);
    PlmnService.findAll()
      .then((plmnList) => {
        setPlmn(
          plmnList.sort((a, b) => {
            if (a.name > b.name) {
              return 1;
            }
            if (a.name < b.name) {
              return -1;
            }
            return 0;
          })
        );
      })
      .catch(() => {
        toast.error(ErrorUnexpected);
      })
      .finally(() => setIsLoading(false));
  }

  function handleOpen(plmnId) {
    setIr21DataModal({ isOpen: true, plmnId });
  }

  function handleClose() {
    setIr21DataModal({ ...ir21DataModal, isOpen: false });
  }

  function handleDownload(data) {
    setShowModal(true);
    setOrganisationId(data.organisation?.id);
    setOrganisationName(data.organisation?.name);
  }

  function exportALL() {
    setIsLoading(true);
    Ir21Service.getExportDataAll()
      .catch(() => {
        toast.error(ErrorUnexpected);
      })
      .finally(() => setIsLoading(false));
  }

  function handleIr21Template(plmn) {
    history.push("/ir21/settings/tadigs/ir21-template", {
      plmnId: plmn.id,
      plmnName: plmn.name,
      ir21Template: plmn.ir21Template,
    });
  }

  function handleDeleteIr21Template(plmn) {
    Swal.fire({
      icon: "warning",
      title: "Confirmation",
      text: "Are you sure you want to delete the IR.21 template?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      closeOnConfirm: true,
    }).then((result) => {
      if (result.isConfirmed) {
        Ir21Service.deleteIr21Template(plmn.id)
          .then(() => {
            updateData();
            toast.success(OperationSuccessfully);
          })
          .catch(() => toast.error(ErrorUnexpected));
      }
    });
  }

  function handleDelete(id) {
    Swal.fire({
      icon: "warning",
      title: "Confirmation",
      text: "Are you sure you want to delete?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      closeOnConfirm: true,
    }).then((result) => {
      if (result.isConfirmed) {
        PlmnService.deleteById(id)
          .then(() => {
            updateData();
            toast.success(OperationSuccessfully);
          })
          .catch(() => toast.error(ErrorUnexpected));
      }
    });
  }

  const [columns] = useState([
    {
      title: "TADIG",
      field: "name",
    },
    {
      title: "NUMBER",
      field: "number",
    },
    {
      title: "MGT",
      field: "e214",
    },
    {
      title: "ORGANISATION",
      field: "organisation.name",
    },
    {
      title: "COUNTRY",
      field: "country.name",
    },
    {
      title: "IR.21",
      field: "hasIr21",
      render: (obj) =>
        obj.hasIr21 ? <FiCheck color="green" /> : <FiX color="red" />,
      customSort: (obj1, obj2) => obj1.hasIr21 - obj2.hasIr21,
      width: "1%",
      lookup: { true: "Found", false: "Not Found" },
    },
  ]);

  return (
    <Row>
      <Col>
        <div className="ibox">
          <div className="ibox-title d-flex justify-content-between">
            <h5>Tadig - List</h5>
            <div>
              <Link
                className="btn btn-primary"
                to={{
                  pathname: "/ir21/settings/tadigs/reg",
                  state: { id: "" },
                }}
              >
                New Tadig
              </Link>
            </div>
          </div>
          <div className="ibox-content">
            <MaterialTable
              title=""
              columns={columns}
              data={plmn}
              isLoading={isLoading}
              components={{
                Container: (props) => <Paper {...props} elevation={0} />,
              }}
              options={{
                rowStyle: {
                  fontSize: 13,
                },
                pageSize: 10,
                pageSizeOptions: [5, 10, 15, 20],
                actionsColumnIndex: -1,
                exportButton: true,
                exportAllData: true,
                searchFieldAlignment: "left",
                toolbarButtonAlignment: "right",
                exportFileName: "tadig",
                filtering: true,
                headerStyle: {
                  textTransform: "uppercase",
                },
              }}
              actions={[
                {
                  icon: () => <FiEdit size={16} color="#007bff" />,
                  tooltip: "EDIT",
                  onClick: (event, rowData) =>
                    history.push("/ir21/settings/tadigs/reg", {
                      id: rowData.id,
                    }),
                },
                {
                  icon: () => <FiFileText size={16} color="#007bff" />,
                  tooltip: "VIEW IR.21 DATA",
                  onClick: (event, rowData) => handleOpen(rowData.id),
                },
                (row) => {
                  if (row.ir21Template || !row.hasIr21) {
                    return {
                      icon: () => <FiFilePlus size={16} color="#007bff" />,
                      tooltip: row.ir21Template
                        ? "EDIT IR.21 TEMPLATE"
                        : "IR.21 TEMPLATE",
                      onClick: (event, rowData) => handleIr21Template(rowData),
                    };
                  }
                },
                (row) => {
                  if (row.ir21Template) {
                    return {
                      icon: () => <FiFileMinus size={16} color="#007bff" />,
                      tooltip: "REMOVE IR.21 TEMPLATE",
                      onClick: (event, rowData) =>
                        handleDeleteIr21Template(rowData),
                    };
                  }
                },
                {
                  icon: () => <FiTrash2 size={16} color="#007bff" />,
                  tooltip: "DELETE",
                  onClick: (event, rowData) => handleDelete(rowData.id),
                },
                {
                  icon: () => <FiFileText size={21} color="#676a6c" />,
                  tooltip: "Export IR.21",
                  isFreeAction: true,
                  onClick: (event, rowData) => exportALL(),
                },
                (row) => {
                  if (row.hasIr21 && !row.ir21Template) {
                    return {
                      icon: () => <FiDownload size={16} color="#007bff" />,
                      tooltip: "Download",
                      onClick: (event, rowData) => handleDownload(rowData),
                    };
                  }
                },
              ]}
            />
          </div>
        </div>
        <Ir21TemplateModal
          modalData={ir21DataModal}
          handleClose={handleClose}
        />
        <Ir21SearchModal
          setShowModal={setShowModal}
          showModal={showModal}
          organisationId={organisationId}
          organisationName={organisationName}
        />
      </Col>
    </Row>
  );
}
